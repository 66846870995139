




























































































































































































































































































































































































































































































































































































































































































































@import '~@/styles/import';

.summay-rateplan-tooltip {
  p:not(:first-child) {
    @apply mt-8;
  }
}

.addons {
  &__list {
    li {
      @apply mb-8 flex gap-x-8;

      &:last-of-type {
        @apply mb-0;
      }
    }
  }
}

